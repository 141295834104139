import jQuery from 'jquery';
(function ($) {
  var input = $('.validate-input .input100');
  $('.validate-form').on('submit', function () {
    var check = true;
    for (var i = 0; i < input.length; i++) {
      if (validate(input[i]) === false) {
        showValidate(input[i]);
        check = false;
      }
    }
    return check;
  });
  $('.validate-form .input100').each(function () {
    $(this).focus(function () {
      hideValidate(this);
    });
  });
  function validate(input) {
    if ($(input).attr('type') === 'email' || $(input).attr('name') === 'email') {
      if ($(input).val().trim().match(/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{1,5}|[0-9]{1,3})(\]?)$/) == null) {
        return false;
      }
    }
    else {
      if ($(input).val().trim() === '') {
        return false;
      }
    }
  }
  function showValidate(input) {
    var thisAlert = $(input).parent();
    $(thisAlert).addClass('alert-validate');
  }
  function hideValidate(input) {
    var thisAlert = $(input).parent();
    $(thisAlert).removeClass('alert-validate');
  }
  window.fnames = [];
  window.ftypes = [];
  window.fnames[1] = 'FNAME';
  window.ftypes[1] = 'text';
  window.fnames[2] = 'LNAME';
  window.ftypes[2] = 'text';
  window.fnames[0] = 'EMAIL';
  window.ftypes[0] = 'email';
  window.fnames[3] = 'ADDRESS';
  window.ftypes[3] = 'address';
  window.fnames[4] = 'PHONE';
  window.ftypes[4] = 'phone';
}(jQuery));
// eslint-disable-next-line
var $mcj = jQuery.noConflict(true);
