import React from 'react';
// import PropTypes from 'prop-types';
import {
  Container
} from 'reactstrap';
import {
  CopyrightInfomation
} from '../App';

const propTypes = {

};
const defaultProps = {

};
const AuthFooter = props => {
  // const {
  //   routes
  // } = props;
  return (
    <Container fluid>
      <CopyrightInfomation />
    </Container>
  );
};

AuthFooter.propTypes = propTypes;
AuthFooter.defaultProps = defaultProps;

export default AuthFooter;
