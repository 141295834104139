import React, {
  useEffect
} from 'react';
import {
  Container,
  Row,
  Col
} from 'reactstrap';

const rootElementId = 'root';
const bgColor = 'bg-gradient-info';
const Public = ({ children }) => {
  const rootElement = document.getElementById(rootElementId);
  useEffect(() => {
    document.body.classList.add(bgColor);
    rootElement.classList.add(bgColor);
    return () => {
      document.body.classList.remove(bgColor);
      rootElement.classList.remove(bgColor);
    };
  });
  return (
    <>
      <Container className="pt-5">
        <Row>
          <Col className="mx-auto" lg="6">
            {children}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Public;
