import React from 'react';
import {
  Link
} from 'react-router-dom';
import {
  Row,
  Col
} from 'reactstrap';
import PropTypes from 'prop-types';
import PWAIssueRegister from '../App/PWAIssueRegister';

const propTypes = {
  rowClassName: PropTypes.string
};
const defaultProps = {
};
const CopyrightInfomation = props => {
  const {
    rowClassName
  } = props;
  return (
    <Row className={rowClassName}>
      <Col xs={10} className="small">
        {process.env.REACT_APP_PWA_NAME} &copy; 2020 <Link to="/PrivacyPolicy" title="Privacy Policy">Privacy</Link> &amp; <Link to="/TermsOfUse" title="Terms of Use">Terms</Link>
      </Col>
      <Col xs={2}>
        <PWAIssueRegister />
      </Col>
    </Row>
  );
};

CopyrightInfomation.propTypes = propTypes;
CopyrightInfomation.defaultProps = defaultProps;

export default CopyrightInfomation;
