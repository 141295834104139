import {
  lazy
} from 'react';
import {
  systemAdminRole,
  adminRole,
  studentRole,
  teacherRole,
  whanauRole
} from '../Domain/Roles';

const Public = lazy(() => import('../../Layouts/Public'));
const Auth = lazy(() => import('../../Layouts/Auth'));
const whanauRoleUp = {
  systemAdminRole,
  adminRole,
  teacherRole,
  studentRole,
  whanauRole
};
const teacherRoleUp = {
  systemAdminRole,
  adminRole,
  teacherRole
};
const teacherStudentWhanauRolesOnly = {
  teacherRole,
  studentRole,
  whanauRole
};
const adminRoleUp = {
  systemAdminRole,
  adminRole
};
const adminRoleOnly = {
  adminRole
};
const systemAdminRoleOnly = {
  systemAdminRole
};
const Routes = {
  homeView: {
    path: '/',
    component: lazy(() => import('../../Views/Home')),
    layout: Auth,
    name: 'Home',
    icon: '',
    roles: {},
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: false
  },
  privacyPolicyPage: {
    path: '/PrivacyPolicy',
    component: lazy(() => import('../../pages/PrivacyPolicy')),
    layout: Public,
    name: 'Privacy Policy',
    icon: '',
    roles: {},
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: true,
    exact: true,
    addNew: false
  },
  termsOfUsePage: {
    path: '/TermsOfUse',
    component: lazy(() => import('../../pages/TermsOfUse')),
    layout: Public,
    name: 'Terms of Use',
    icon: '',
    roles: {},
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: true,
    exact: true,
    addNew: false
  },
  loginView: {
    path: '/Login',
    component: lazy(() => import('../../Views/Login')),
    layout: Public,
    name: 'Login',
    icon: '',
    roles: {},
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: true,
    exact: true,
    addNew: false
  },
  registerView: {
    path: '/Register',
    component: lazy(() => import('../../Views/Register')),
    layout: Public,
    name: 'Login',
    icon: '',
    roles: {},
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: true,
    exact: true,
    addNew: false
  },
  forgotPasswordView: {
    path: '/ForgotPassword',
    component: lazy(() => import('../../Views/ForgotPassword')),
    layout: Public,
    name: 'Forgot Password',
    icon: '',
    roles: {},
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: true,
    exact: true,
    addNew: false
  },
  systemAdminDashBoardView: {
    path: '/SystemAdminDashBoard',
    component: lazy(() => import('../../Views/SystemAdminDashBoardView')),
    layout: Auth,
    name: 'Admin Dashboard',
    icon: 'ni ni-tv-2',
    roles: systemAdminRoleOnly,
    excludeFromSidebar: false,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: false
  },
  adminDashBoardView: {
    path: '/AdminDashBoard',
    component: lazy(() => import('../../Views/AdminDashBoardView')),
    layout: Auth,
    name: 'Admin Dashboard',
    icon: 'ni ni-tv-2',
    roles: adminRoleOnly,
    excludeFromSidebar: false,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: false
  },
  dashBoardView: {
    path: '/DashBoard',
    component: lazy(() => import('../../Views/DashBoardView')),
    layout: Auth,
    name: 'Dashboard',
    icon: 'ni ni-tv-2',
    roles: teacherStudentWhanauRolesOnly,
    excludeFromSidebar: false,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: false
  },
  accountView: {
    path: '/Account',
    component: lazy(() => import('../../Views/AccountView')),
    layout: Auth,
    name: 'Account',
    icon: 'ni ni-single-02',
    roles: whanauRoleUp,
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: false
  },
  usersView: {
    path: '/Users',
    component: lazy(() => import('../../Views/UsersView')),
    layout: Auth,
    name: 'Users',
    icon: 'fas fa-users',
    roles: adminRoleUp,
    excludeFromSidebar: false,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: false
  },
  userView: {
    path: '/Users/:uid',
    component: lazy(() => import('../../Views/UserView')),
    layout: Auth,
    name: 'User',
    icon: 'ni ni-single-02',
    roles: adminRoleUp,
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: true
  },
  classesView: {
    path: '/Classes',
    component: lazy(() => import('../../Views/ClassesView')),
    layout: Auth,
    name: 'Classes',
    icon: 'fas fa-chalkboard',
    roles: teacherRoleUp,
    excludeFromSidebar: false,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: false
  },
  classView: {
    path: '/Classes/:cid',
    component: lazy(() => import('../../Views/ClassView')),
    layout: Auth,
    name: 'Class',
    icon: 'fas fa-chalkboard',
    roles: teacherRoleUp,
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: true
  },
  studentsView: {
    path: '/Students',
    component: lazy(() => import('../../Views/StudentsView')),
    layout: Auth,
    name: 'Students',
    icon: 'fas fa-user-graduate',
    roles: teacherRoleUp,
    excludeFromSidebar: false,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: false
  },
  studentView: {
    path: '/Students/:uid',
    component: lazy(() => import('../../Views/StudentView')),
    layout: Auth,
    name: 'Student',
    icon: 'fas fa-user-graduate',
    roles: teacherRoleUp,
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: true
  },
  whanauMembersView: {
    path: '/WhanauMembers',
    component: lazy(() => import('../../Views/WhanauMembersView')),
    layout: Auth,
    name: 'Whanau',
    icon: 'fas fa-user-friends',
    roles: teacherRoleUp,
    excludeFromSidebar: false,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: false
  },
  whanauMemberView: {
    path: '/WhanauMembers/:uid',
    component: lazy(() => import('../../Views/WhanauMemberView')),
    layout: Auth,
    name: 'Whanau',
    icon: 'fas fa-user-friends',
    roles: teacherRoleUp,
    excludeFromSidebar: true,
    excludeFromAuthenticatedRoutes: false,
    exact: true,
    addNew: true
  },
};

export default Routes;
