import React from 'react';
import PropTypes from 'prop-types';
import {
  Route
} from 'react-router-dom';

const propTypes = {
  component: PropTypes.element.isRequired,
  layout: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired
};
const defaultProps = {
  path: '/'
};
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        <Component {...props} />
      </Layout>
    )}
  />
);

AppRoute.propTypes = propTypes;
AppRoute.defaultProps = defaultProps;

export default AppRoute;
