import React, {
  useState,
  useRef
} from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody
} from 'reactstrap';
import PropTypes from 'prop-types';

const propTypes = {
  appName: PropTypes.string,
  src: PropTypes.string,
  height: PropTypes.number
};
const defaultProps = {
  appName: 'App Name',
  height: 400
};
const IssueRegister = props => {
  const [showIssueRegisterModal, setShowIssueRegisterModal] = useState(false);
  const handleIssueRegisterModalToggle = () => setShowIssueRegisterModal(!showIssueRegisterModal);
  const handleIssueRegisterModalOpened = () => {
    // eslint-disable-next-line
    issueRegisterIFrameElement.current.src = issueRegisterIFrameElement.current.src;
  };
  const issueRegisterIFrameElement = useRef(null);
  const {
    appName,
    src,
    height
  } = props;
  return (
    <>
      <Button
        className="p-0 float-right"
        size="md"
        color="link"
        title="Report a Bug, Suggest a Feature or Give Feedback"
        onClick={handleIssueRegisterModalToggle}>
        <i className="fa fa-bug" />
      </Button>
      <Modal isOpen={showIssueRegisterModal} centered={true} size="lg" toggle={handleIssueRegisterModalToggle} fade={true} onOpened={handleIssueRegisterModalOpened}>
        <ModalHeader className="modal-header-issue-register" toggle={handleIssueRegisterModalToggle}>{appName}: Issue Register</ModalHeader>
        <ModalBody>
          <iframe
            ref={issueRegisterIFrameElement}
            title={`${appName}: Issue Register`}
            src={src}
            frameBorder="0"
            scrolling="yes"
            seamless="seamless"
            height={height}
            width="100%"
            style={{
              position: 'relative',
              width: '1px',
              minWidth: '100%'
            }} />
          <script src="https://services.cognitoforms.com/scripts/embed.js"></script>
        </ModalBody>
      </Modal>
    </>
  );
};

IssueRegister.propTypes = propTypes;
IssueRegister.defaultProps = defaultProps;

export default IssueRegister;
