import React, {
  Suspense
} from 'react';
import {
  BrowserRouter,
  Switch
} from 'react-router-dom';
import Routes from './Routes';
import {
  withAuthentication
} from '../Firebase';
import LoadingOverlayModal from '../App/LoadingOverlayModal';
import AppRoute from '../../Layouts';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css';
import '../../assets/css/util.css';
import '../../assets/css/main.css';
import '../../assets/plugins/nucleo/css/nucleo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import '../../assets/scss/argon-dashboard-react.scss';

require('../../assets/js/main.js');

const App = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingOverlayModal />}>
        <Switch>
          {
            Object.keys(Routes).map((routeKey, index) => {
              const route = Routes[routeKey];
              const { path, component, layout } = route;
              // console.log(`route: ${JSON.stringify(route, null, 2)}`);
              return (
                <AppRoute path={path} component={component} layout={layout} key={index} exact />
              );
            })
          }
        </Switch>
      </Suspense>
    </BrowserRouter>
  );
};

export default withAuthentication(App);
