import React from 'react';
import IssueRegister from '../App/IssueRegister';

const PWAIssueRegister = () => {
  return (
    <IssueRegister
      appName={`${process.env.REACT_APP_PWA_NAME} v${process.env.REACT_APP_PWA_BUILD_VERSION}`}
      src="https://services.cognitoforms.com/f/29l1qWmg80-sstIuTKQ3HA?id=1"
      height={405}
    />
  );
};

export default PWAIssueRegister;
