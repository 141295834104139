import React, {
  useState
} from 'react';
import {
  NavLink as NavLinkRRD,
  Link
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  withFirebase
} from '../Firebase/Contexts/firebaseContext';
import {
  Navbar,
  Container,
  Row,
  Col,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
} from 'reactstrap';

const propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
  logo: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    href: PropTypes.string
  })
};
const defaultProps = {

};
const AuthSidebar = props => {
  const [collapseOpen, setCollapseOpen] = useState(false);
  const {
    routes,
    logo,
    firebase
  } = props;
  const {
    REACT_APP_PWA_NAME
  } = process.env;
  const getAuthSidebarRoutes = routes => {
    const authSidebarRoutes = [];
    routes.map(route => {
      if (!route.excludeFromSidebar) {
        authSidebarRoutes.push(route);
      }
      return null;
    });
    return authSidebarRoutes;
  };
  const createLinks = routes => {
    return routes.map((prop, key) => {
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
        </NavItem>
      );
    });
  };
  const toggleCollapse = async e => {
    e.preventDefault();
    setCollapseOpen(!collapseOpen);
  };
  const closeCollapse = async e => {
    e.preventDefault();
    setCollapseOpen(false);
  };
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white"
      expand="md"
    >
      <Container fluid>
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        <NavbarBrand href={logo.path} className="py-0">
          <img
            src={logo.src}
            height="24"
            className="d-inline-block"
            alt={logo.alt}
          /><span className="h3">&nbsp;{REACT_APP_PWA_NAME}</span>
        </NavbarBrand>
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle>
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img
                    alt="..."
                    src={require("../../assets/images/te-rangihakahaka-bg-1350x900.jpg")}
                  />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem to="/Account" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My Profile</span>
              </DropdownItem>
              {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-calendar-grid-58" />
                <span>Activity</span>
              </DropdownItem>
              <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-support-16" />
                <span>Support</span>
              </DropdownItem> */}
              <DropdownItem divider />
              <DropdownItem href="#pablo" onClick={async e => {
                e.preventDefault();
                await firebase.signOut();
              }}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Collapse navbar isOpen={collapseOpen}>
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to={logo.path}>
                  <img alt={logo.alt} src={logo.src} />
                </Link>
              </Col>
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>
          <Nav navbar>{createLinks(getAuthSidebarRoutes(routes))}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

AuthSidebar.propTypes = propTypes;
AuthSidebar.defaultProps = defaultProps;

export default withFirebase(AuthSidebar);
