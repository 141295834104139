import React, {
  useState,
  useEffect
} from 'react';
import {
  Redirect
} from 'react-router-dom';
import {
  LoadingOverlayModal,
  Routes,
  Roles
} from '../components/App';
import AuthUserContext from '../components/Firebase/Contexts/authUserContext';
import withEmailVerification from '../components/Firebase/HighOrder/withEmailVerification';  // Works!
import withAuthorization from '../components/Firebase/HighOrder/withAuthorization';       // Fails!
import {
  compose
} from 'recompose';
import AuthNavbar from '../components/Navbars';
import AuthSidebar from '../components/Sidebars';
import AuthFooter from '../components/Footers';

const hasRole = (roles, role) => {
  return roles && !!roles[role];
};
const hasRoles = (roles, rolesToCheck) => {
  let hasRolesConfirmed = false;
  Object.keys(roles).map(roleKey => {
    if (!hasRolesConfirmed) {
      hasRolesConfirmed = hasRole(rolesToCheck, roleKey);
    }
    return null;
  });
  return hasRolesConfirmed;
};
const getAuthenticatedRoutes = (authUserRoles, logRoutes = false) => {
  const authenticatedRoutes = [];
  Object.keys(Routes).map(routeKey => {
    const route = Routes[routeKey];
    if (route && route.path && !route.excludeFromAuthenticatedRoutes && hasRoles(route.roles, authUserRoles)) {
      if (logRoutes) {
        console.log(`authenticatedRoute: ${JSON.stringify(route, null, 2)}`);
      }
      authenticatedRoutes.push(route);
    }
    return null;
  });
  return authenticatedRoutes;
};
const applyRedirect = authUser => {
  const {
    homeView: from,
    systemAdminDashBoardView,
    adminDashBoardView,
    dashBoardView
  } = Routes;
  const {
    systemAdminRole,
    adminRole,
    teacherRole,
    studentRole,
    whanauRole
  } = Roles;
  const authUserRoles = authUser && authUser.roles
    ? authUser.roles
    : [];
  const to = hasRole(authUserRoles, systemAdminRole)
    ? systemAdminDashBoardView
    : hasRole(authUserRoles, adminRole)
      ? adminDashBoardView
      : hasRoles(authUserRoles, [teacherRole, studentRole, whanauRole])
        ? dashBoardView
        : null;
  console.log(`from: ${from.path}, to: ${to.path}`);
  return (
    to
      ? <Redirect from={from.path} to={to.path} />
      : null
  );
};
const Auth = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [authUser, setAuthUser] = useState(false);
  const [authenticatedRoutes, setAuthenticatedRoutes] = useState([]);
  const {
    props
  } = children;
  const getBrandText = path => {
    for (let i = 0; i < authenticatedRoutes.length; i++) {
      if (
        path.indexOf(
          authenticatedRoutes[i].layout + authenticatedRoutes[i].path
        ) !== -1
      ) {
        return authenticatedRoutes[i].name;
      }
    }
    return "Admin Dashboard";
  };
  useEffect(() => {
    setIsLoading(true);
    if (authUser && authUser.roles) {
      const authenticatedRoutes = getAuthenticatedRoutes(authUser.roles);
      setAuthenticatedRoutes(authenticatedRoutes);
    }
    setIsLoading(false);
  }, [authUser]);
  return (
    <AuthUserContext.Consumer>
      {
        authUser => (
          isLoading
            ? <LoadingOverlayModal />
            : <>
              {setAuthUser(authUser)}
              <AuthSidebar
                routes={authenticatedRoutes}
                logo={{
                  alt: `${process.env.REACT_APP_PWA_NAME} Logo`,
                  src: require('../assets/images/mock-logo-180x180.png'),
                  path: Routes.homeView.path
                }}
              />
              <div className="main-content">
                <AuthNavbar {...props} authUser={authUser} brandText={getBrandText(props.location.pathname)} />
                {applyRedirect(authUser)}
                {children}
                <div>
                  <AuthFooter />
                </div>
              </div>
            </>
        )
      }
    </AuthUserContext.Consumer>
  );
};

const condition = authUser => !!authUser && !!authUser.active;
console.log(`typeof compose: ${typeof compose}, typeof withEmailVerification: ${typeof withEmailVerification}, typeof condition: ${typeof condition}, typeof withAuthorization: ${typeof withAuthorization}, typeof Auth: ${typeof Auth}`);
export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(Auth);
